<template>
  <img alt="Logo Arli-Jo" src="./assets/logo.png" />
  <p>Opent binnenkort</p>
  <!-- <Countdown
    mainColor="#E2BC75"
    labelColor="#E2BC75"
    deadlineISO="2021-12-24T11:00:00.000Z"
    :labels="{ days: 'Dagen', hours: 'Uren', minutes: 'Minuten', seconds: 'Seconden' }"
    :flipAnimation="true"
    mainFlipBackgroundColor="#171a21"
    secondFlipBackgroundColor="#171a21"
  /> -->
  <!-- labels="{days:'Dagen',hours:'Uren',minutes:'Minuten',seconds:'Seconden'}" -->
</template>

<script>
// import { Countdown } from "vue3-flip-countdown";
export default {
  name: "App",
  // components: {
  //   Countdown,
  // },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Alegreya+SC");
p {
  font-family: "Alegreya SC";
  font-size: xx-large;
  color: #e2bc75;
}
img {
  width: 20rem;
  margin-bottom: 10rem;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

html {
  background-color: #1f232d;
}
</style>
